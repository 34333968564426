(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Bookitlive = {
    // All pages
    'common': {
      init: function() {

        /* ========================================================================
         *  Back to top button
         * ======================================================================== */

        function backToTop() {
          var toTopBtn = '<button class="btn-to-top"><span>Back to Top</span></button>';

          // Append to footer
          $(toTopBtn).appendTo($('.content-info .container'));

          $('.btn-to-top').on('click', function() {
            $('body, html').stop().animate({'scrollTop': 0}, 500);
          });
        }

        backToTop();

        /* ========================================================================
         *  Mobile menu toggle
         * ======================================================================== */

        function mobileToggle() {
          var button = '<button class="menu-toggle" id="menu-toggle" aria-expanded="false" aria-controls="nav-primary"><span class="sr-only">Toggle Menu</span></button>';
          var menu = $('.nav-primary');

          menu.attr('aria-hidden', true);

          if (!$('#menu-toggle').length) {
            $('.banner .container').prepend(button);
          }

          $('#menu-toggle').on('click', function () {
            var state = $(this).attr('aria-expanded') === 'false' ? true : false;
            $(this).attr('aria-expanded', state);
            menu.attr('aria-hidden', !state);
            $('body').toggleClass('menu-open', state);
          });
        }

        mobileToggle();


        /* ========================================================================
         *  Mobile sub menu
         * ======================================================================== */

        function subMenuToggle() {
          var subMenuParent = $('.nav-primary > .nav > .menu-item-has-children');

          subMenuParent.each(function () {
            var $this = $(this);
            var subMenu = $this.children('.mega-wrap');
            var id = 'sub-menu-' + $this.index();
            var subMenuBtn = '<button class="sub-menu-toggle" aria-expanded="false" aria-controls="' + id + '"><span tabindex="-1"><span class="sr-only">toggle sub menu</span></span></button>';


            if (!$this.children('button').length) {
              $this.prepend(subMenuBtn);
            }
            subMenu.attr({'id': id, 'aria-hidden': true});

            var button = $this.children('button');

            button.on('click', function () {
              var state = $(this).attr('aria-expanded') === 'false' ? true : false;
              $(this).attr('aria-expanded', state);
              subMenu.attr('aria-hidden', !state);

              // Reset others
              $this.siblings().find('button').attr('aria-expanded', false);
              $this.siblings().find('.mega-wrap').attr('aria-hidden', true);

            });

          });

          $(document).mouseup(function (e) {
            var container = $(".nav-primary");

            if (!container.is(e.target) && container.has(e.target).length === 0) {
              subMenuParent.find('.sub-menu-toggle').attr('aria-expanded', false);
              subMenuParent.find('.mega-wrap').attr('aria-hidden', true);
            }
          });
        }

        var resizeTimer;

        $(window).on('resize', function() {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(function() {
            if (window.matchMedia("(max-width: 666px)").matches) {
              mobileToggle();
            } else {
              if ($('#menu-toggle').length || $('.sub-menu-toggle').length) {
                $('#menu-toggle').unbind().remove();
              }
            }
          }, 250);
        });

        subMenuToggle();
      },
      finalize: function () {

      }
    },
    // Home page
    'home': {
      init: function () {
        /* ========================================================================
         *  Category slider
         * ======================================================================== */

        var heroTitles = [];
        $('.hero').each(function() {
          heroTitles.push($(this).data('title'));
        });

        var hero = new Swiper('.hero-slider', {
          effect: 'fade',
          pagination: {
            el: '.hero-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              return '<li class="' + className + '">' + heroTitles[index] + '</li>';
            },
          },
        });

        var categorySlider = new Swiper('.category-slider', {
          slidesPerView: 4,
          spaceBetween: 24,
          pagination: {
            el: '.category-swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            1200: {
              slidesPerView: 4,
              spaceBetween: 24,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            420: {
              slidesPerView: 1,
              spaceBetween: 24,
            }
          }
        });

        var caseSlider = new Swiper('.case-study-slider', {
          pagination: {
            el: '.case-swiper-pagination',
            clickable: true,
          },
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function () {
        var caseSlider = new Swiper('.case-study-slider', {
          pagination: {
            el: '.case-swiper-pagination',
            clickable: true,
          },
        });
      }
    },
    'industry': {
      init: function () {
        Tabs.init();
      }
    },
    'faq': {
      init: function () {
        Collapsible.init();
      }
    },
    'what_is_bookitlive': {
      init: function () {
        $('.watch-video').each(function() {
          var $this = $(this);
          var id = 'video-' + $this.index();
          var vid_button = '<button class="video-trigger" aria-expanded="false" aria-controls="' + id + '" />';
          $this.children('.embed-container').wrapAll('<div id="' + id + '" aria-hidden="true">');

          $('.video-img').wrapInner(vid_button);

          $this.find('button').on('click', function() {
            var state = $(this).attr('aria-expanded') === 'false' ? true : false;
            $(this).attr('aria-expanded', state);
            $this.find('.embed-container').parent().attr('aria-hidden', !state);
          });
        });

      }
    }
  };

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
fire: function(func, funcname, args) {
var fire;
var namespace = Bookitlive;
funcname = (funcname === undefined) ? 'init' : funcname;
fire = func !== '';
fire = fire && namespace[func];
fire = fire && typeof namespace[func][funcname] === 'function';

if (fire) {
namespace[func][funcname](args);
}
},
loadEvents: function() {
// Fire common init JS
UTIL.fire('common');

// Fire page-specific init JS, and then finalize JS
$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
UTIL.fire(classnm);
UTIL.fire(classnm, 'finalize');
});

// Fire common finalize JS
UTIL.fire('common', 'finalize');
}
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
